var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "Groups", fluid: "", tag: "section" } },
    [
      _c(
        "FilterSideBar",
        {
          attrs: {
            width: _vm.isMobile ? "" : "400px",
            loading: _vm.loading,
            filterIsEmpty: _vm.filterIsEmpty,
          },
          on: { clear: _vm.onClear },
        },
        [
          _c("FreeGamesForm", {
            on: { filter: _vm.onFilter },
            model: {
              value: _vm.filter,
              callback: function ($$v) {
                _vm.filter = $$v
              },
              expression: "filter",
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { staticClass: "v-card--material pa-3" },
                [
                  _c(
                    "CardHeading",
                    { attrs: { title: "Free games offers" } },
                    [
                      _vm._usrFlagsSome({
                        key: "free_games.detail",
                        val: _vm.permissions.CREATE,
                      })
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                elevation: "1",
                                fab: "",
                                small: "",
                                color: "primary",
                              },
                              on: { click: _vm.openCreate },
                            },
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      "must-sort": "",
                      headers: _vm.filteredHeaders,
                      items: _vm.loading
                        ? new Array(_vm.options.itemsPerPage).fill({ name: 1 })
                        : _vm.list,
                      options: _vm.options,
                      "item-class": _vm.itemClass,
                      "sort-by": "valid_from",
                      "sort-desc": true,
                      "server-items-length": _vm.loading
                        ? _vm.options.itemsPerPage
                        : _vm.total_records,
                      loading: _vm.loading,
                      "footer-props": _vm.footerProps,
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                      "click:row": _vm.handleClick,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "top",
                          fn: function ({ pagination }) {
                            return [
                              _c(
                                "v-data-footer",
                                _vm._b(
                                  {
                                    staticClass: "anton",
                                    attrs: {
                                      options: _vm.options,
                                      pagination: pagination,
                                    },
                                    on: {
                                      "update:options": function ($event) {
                                        _vm.options = $event
                                      },
                                    },
                                  },
                                  "v-data-footer",
                                  _vm.footerProps,
                                  false
                                )
                              ),
                            ]
                          },
                        },
                        _vm.loading
                          ? {
                              key: "item",
                              fn: function () {
                                return [
                                  _c(
                                    "tr",
                                    { staticClass: "table-item" },
                                    _vm._l(
                                      _vm.filteredHeaders,
                                      function (header) {
                                        return _c(
                                          "td",
                                          {
                                            key: header.value,
                                            class: `text-${header.align}`,
                                          },
                                          [
                                            _c("v-skeleton-loader", {
                                              attrs: { type: "text" },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        {
                          key: `item.name`,
                          fn: function ({ item }) {
                            return [_vm._v(" " + _vm._s(item.name) + " ")]
                          },
                        },
                        {
                          key: `item.valid_from`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("Date")(item.valid_from)) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.valid_to`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("Date")(item.valid_to)) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.gamble_enabled`,
                          fn: function ({ item }) {
                            return [
                              item.gamble_enabled
                                ? _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v(" mdi-check ")]
                                  )
                                : _c("v-icon", { attrs: { color: "error" } }, [
                                    _vm._v(" mdi-close "),
                                  ]),
                            ]
                          },
                        },
                        {
                          key: `item.offer_rules`,
                          fn: function ({ item }) {
                            return [
                              item.internal && item.offer_rules !== null
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "px-0",
                                      attrs: { color: "primary" },
                                    },
                                    [_vm._v(" mdi-check ")]
                                  )
                                : _vm._e(),
                              _c(
                                "v-tooltip",
                                {
                                  key: `internal-${item.internal}-noshow`,
                                  attrs: {
                                    color: "warning darken-2",
                                    bottom: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            !item.internal
                                              ? _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color: "warning",
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" mdi-minus ")]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v("Unavailable for external offers"),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  key: `internal-${item.internal}-norule`,
                                  attrs: {
                                    color: "warning darken-2",
                                    bottom: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            item.internal &&
                                            item.offer_rules === null
                                              ? _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color: "warning",
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" mdi-exclamation ")]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v("Offer doesn't have a rule"),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.internal`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  key: `internal-${item.internal}-chip`,
                                  attrs: {
                                    color: "warning darken-2",
                                    bottom: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            !item.internal
                                              ? _c(
                                                  "v-chip",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "v-chip",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" Casino ")]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Offer was created by external API call"
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.status`,
                          fn: function ({ item }) {
                            return [
                              item.is_removed
                                ? _c(
                                    "v-chip",
                                    { attrs: { small: "", color: "error" } },
                                    [_vm._v(" Closed ")]
                                  )
                                : new Date(item.valid_from).getTime() >
                                  _vm.timeNow
                                ? _c(
                                    "v-chip",
                                    { attrs: { small: "", color: "warning" } },
                                    [_vm._v(" Pre-Activation ")]
                                  )
                                : new Date(item.valid_to).getTime() <
                                  _vm.timeNow
                                ? _c(
                                    "v-chip",
                                    { attrs: { small: "", color: "warning" } },
                                    [_vm._v(" Expired ")]
                                  )
                                : _c(
                                    "v-chip",
                                    { attrs: { small: "", color: "success" } },
                                    [_vm._v(" Active ")]
                                  ),
                            ]
                          },
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  key: `${item.id}-${item.is_removed}-deleteBtn`,
                                  attrs: {
                                    color: "primary darken-2",
                                    bottom: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _vm._usrFlagsSome({
                                              key: "free_games.detail",
                                              val: _vm.permissions.DELETE,
                                            }) && !item.is_removed
                                              ? _c("ConfirmDialog2", {
                                                  attrs: {
                                                    persistent: "",
                                                    confirmBtnText: "Delete",
                                                    cancelBtnText: "Back",
                                                    confirmBtnColor: "error",
                                                    titleClass: "error",
                                                    cancelBtnColor:
                                                      "kajot-text",
                                                    shouldShow: true,
                                                  },
                                                  on: {
                                                    confirm: function ($event) {
                                                      return _vm.onDeleteItem(
                                                        item
                                                      )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "body",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              " This offer will be deleted. "
                                                            ),
                                                            _c("br"),
                                                            _c("b", [
                                                              _vm._v(
                                                                "Do you want to proceed?"
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          showConfirm,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      size: "22",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          $event.preventDefault()
                                                                          return showConfirm.apply(
                                                                            null,
                                                                            arguments
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " mdi-delete "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", { staticClass: "white--text" }, [
                                    _vm._v("Delete"),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  key: `${item.id}-${item.is_removed}-restoreBtn`,
                                  attrs: {
                                    color: "primary darken-2",
                                    bottom: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _vm._usrFlagsSome({
                                              key: "free_games.detail",
                                              val: _vm.permissions.DELETE,
                                            }) && item.is_removed
                                              ? _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          size: "22",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onRestoreItem(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" mdi-restore ")]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", { staticClass: "white--text" }, [
                                    _vm._v("Restore"),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.createDialog,
            callback: function ($$v) {
              _vm.createDialog = $$v
            },
            expression: "createDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { class: ["primary", "lighten-1", "font-weight-bold"] },
                [
                  _c("span", { staticClass: "text-h5" }, [
                    _vm._v("Create Offer"),
                  ]),
                ]
              ),
              _c(
                "v-stepper",
                {
                  model: {
                    value: _vm.currentStep,
                    callback: function ($$v) {
                      _vm.currentStep = $$v
                    },
                    expression: "currentStep",
                  },
                },
                [
                  _c(
                    "v-stepper-header",
                    [
                      _vm._l(_vm.steps, function (step, index) {
                        return [
                          _c(
                            "v-stepper-step",
                            {
                              key: `step-${index}`,
                              attrs: {
                                editable: "",
                                complete: _vm.currentStep > index,
                                step: index,
                              },
                            },
                            [_vm._v(" " + _vm._s(step.name) + " ")]
                          ),
                          index < _vm.steps.length - 1
                            ? _c("v-divider", { key: `step-${index}-divider` })
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                  _c(
                    "v-stepper-items",
                    [
                      _vm._l(_vm.steps, function (step, index) {
                        return [
                          _c(
                            "v-stepper-content",
                            {
                              key: `step-${index}-content`,
                              attrs: { step: index },
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(step.component, {
                                    ref: `step${index}`,
                                    refInFor: true,
                                    tag: "component",
                                    model: {
                                      value: step.model,
                                      callback: function ($$v) {
                                        _vm.$set(step, "model", $$v)
                                      },
                                      expression: "step.model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _vm.currentStep == 1
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "" },
                                          on: { click: _vm.prevStep },
                                        },
                                        [_vm._v(" Back ")]
                                      )
                                    : _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.createDialog = false
                                              _vm.creatingOffer = {}
                                              _vm.currentStep = 0
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                  _c("v-spacer"),
                                  index < _vm.steps.length - 1
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            text: "",
                                            disabled: _vm.loading,
                                          },
                                          on: { click: _vm.nextStep },
                                        },
                                        [_vm._v(" Next ")]
                                      )
                                    : _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            text: "",
                                            disabled: _vm.loading,
                                          },
                                          on: { click: _vm.submit },
                                        },
                                        [_vm._v(" Submit ")]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }