<template>
	<!-- v-if="_usrFlagsSome(permissions.readPlayers)" -->
	<v-container
		id="Groups"
		fluid
		tag="section">
		<FilterSideBar
			@clear="onClear"
			:width="isMobile ? '' : '400px'"
			:loading="loading"
			:filterIsEmpty="filterIsEmpty">
			<FreeGamesForm
				v-model="filter"
				@filter="onFilter"></FreeGamesForm>
		</FilterSideBar>
		<v-row dense>
			<v-col>
				<v-card class="v-card--material pa-3">
					<CardHeading :title="'Free games offers'">
						<v-btn
							v-if="
								_usrFlagsSome({
									key: 'free_games.detail',
									val: permissions.CREATE,
								})
							"
							elevation="1"
							fab
							small
							color="primary"
							@click="openCreate">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</CardHeading>
					<v-data-table
						must-sort
						:headers="filteredHeaders"
						:items="
							loading ? new Array(options.itemsPerPage).fill({ name: 1 }) : list
						"
						:options.sync="options"
						:item-class="itemClass"
						sort-by="valid_from"
						:sort-desc="true"
						:server-items-length="
							loading ? options.itemsPerPage : total_records
						"
						:loading="loading"
						:footer-props="footerProps"
						@click:row="handleClick"
						class="elevation-1">
						<template #top="{ pagination }">
							<v-data-footer
								class="anton"
								:options.sync="options"
								v-bind="footerProps"
								:pagination="pagination"></v-data-footer>
						</template>

						<template
							#item
							v-if="loading">
							<tr class="table-item">
								<td
									v-for="header in filteredHeaders"
									:class="`text-${header.align}`"
									:key="header.value">
									<v-skeleton-loader type="text"></v-skeleton-loader>
								</td>
							</tr>
						</template>
						<template v-slot:[`item.name`]="{ item }">
							{{ item.name }}
							<!-- <a @click="handleClick(item)">{{ item.name }}</a> -->
							<!-- <v-list-item link>asdf</v-list-item> -->
						</template>
						<template v-slot:[`item.valid_from`]="{ item }">
							{{ item.valid_from | Date }}
						</template>
						<template v-slot:[`item.valid_to`]="{ item }">
							{{ item.valid_to | Date }}
						</template>
						<template v-slot:[`item.gamble_enabled`]="{ item }">
							<v-icon
								v-if="item.gamble_enabled"
								color="primary">
								mdi-check
							</v-icon>
							<v-icon
								v-else
								color="error">
								mdi-close
							</v-icon>
						</template>
						<template v-slot:[`item.offer_rules`]="{ item }">
							<v-icon
								class="px-0"
								v-if="item.internal && item.offer_rules !== null"
								color="primary">
								mdi-check
							</v-icon>

							<v-tooltip
								:key="`internal-${item.internal}-noshow`"
								color="warning darken-2"
								bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-on="on"
										v-bind="attrs"
										v-if="!item.internal"
										color="warning">
										mdi-minus
									</v-icon>
								</template>
								<span>Unavailable for external offers</span>
							</v-tooltip>

							<v-tooltip
								:key="`internal-${item.internal}-norule`"
								color="warning darken-2"
								bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-on="on"
										v-bind="attrs"
										v-if="item.internal && item.offer_rules === null"
										color="warning">
										mdi-exclamation
									</v-icon>
								</template>
								<span>Offer doesn't have a rule</span>
							</v-tooltip>
						</template>
						<template v-slot:[`item.internal`]="{ item }">
							<v-tooltip
								:key="`internal-${item.internal}-chip`"
								color="warning darken-2"
								bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-chip
										v-on="on"
										v-bind="attrs"
										v-if="!item.internal">
										Casino
									</v-chip>
								</template>
								<span>Offer was created by external API call</span>
							</v-tooltip>
						</template>
						<template v-slot:[`item.status`]="{ item }">
							<v-chip
								small
								v-if="item.is_removed"
								color="error">
								Closed
							</v-chip>
							<v-chip
								small
								color="warning"
								v-else-if="new Date(item.valid_from).getTime() > timeNow">
								Pre-Activation
							</v-chip>
							<v-chip
								small
								color="warning"
								v-else-if="new Date(item.valid_to).getTime() < timeNow">
								Expired
							</v-chip>
							<v-chip
								small
								v-else
								color="success">
								Active
							</v-chip>
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<v-tooltip
								:key="`${item.id}-${item.is_removed}-deleteBtn`"
								color="primary darken-2"
								bottom>
								<template v-slot:activator="{ on, attrs }">
									<ConfirmDialog2
										persistent
										:confirmBtnText="'Delete'"
										:cancelBtnText="'Back'"
										:confirmBtnColor="'error'"
										:titleClass="'error'"
										:cancelBtnColor="'kajot-text'"
										:shouldShow="true"
										v-if="
											_usrFlagsSome({
												key: 'free_games.detail',
												val: permissions.DELETE,
											}) && !item.is_removed
										"
										@confirm="onDeleteItem(item)">
										<template v-slot:body>
											This offer will be deleted.
											<br />
											<b>Do you want to proceed?</b>
										</template>
										<template v-slot:default="{ showConfirm }">
											<v-icon
												v-bind="attrs"
												v-on="on"
												color="primary"
												size="22"
												@click.stop.prevent="showConfirm">
												mdi-delete
											</v-icon>
										</template>
									</ConfirmDialog2>
								</template>
								<span class="white--text">Delete</span>
							</v-tooltip>
							<v-tooltip
								:key="`${item.id}-${item.is_removed}-restoreBtn`"
								color="primary darken-2"
								bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-bind="attrs"
										v-on="on"
										v-if="
											_usrFlagsSome({
												key: 'free_games.detail',
												val: permissions.DELETE,
											}) && item.is_removed
										"
										color="primary"
										size="22"
										@click="onRestoreItem(item)">
										mdi-restore
									</v-icon>
								</template>
								<span class="white--text">Restore</span>
							</v-tooltip>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog
			v-model="createDialog"
			max-width="500px">
			<v-card>
				<v-card-title :class="['primary', 'lighten-1', 'font-weight-bold']">
					<span class="text-h5">Create Offer</span>
				</v-card-title>
				<v-stepper v-model="currentStep">
					<v-stepper-header>
						<template v-for="(step, index) in steps">
							<v-stepper-step
								:key="`step-${index}`"
								editable
								:complete="currentStep > index"
								:step="index">
								{{ step.name }}
							</v-stepper-step>
							<v-divider
								:key="`step-${index}-divider`"
								v-if="index < steps.length - 1"></v-divider>
						</template>
					</v-stepper-header>
					<v-stepper-items>
						<template v-for="(step, index) in steps">
							<v-stepper-content
								:step="index"
								:key="`step-${index}-content`">
								<v-card-text>
									<component
										:ref="`step${index}`"
										:is="step.component"
										v-model="step.model"></component>
								</v-card-text>
								<v-card-actions>
									<v-btn
										v-if="currentStep == 1"
										text
										@click="prevStep">
										Back
									</v-btn>
									<v-btn
										text
										v-else
										@click="
											createDialog = false;
											creatingOffer = {};
											currentStep = 0;
										">
										Cancel
									</v-btn>
									<v-spacer />
									<v-btn
										color="primary"
										text
										@click="nextStep"
										:disabled="loading"
										v-if="index < steps.length - 1">
										Next
									</v-btn>
									<v-btn
										color="primary"
										text
										@click="submit"
										:disabled="loading"
										v-else>
										Submit
									</v-btn>
								</v-card-actions>
							</v-stepper-content>
						</template>
					</v-stepper-items>
				</v-stepper>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<script>
import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import FreeGamesForm from "../../components/freeGames/FreeGamesForm.vue";
import offerDetailFormVue from "../../components/freeGames/offerDetailForm.vue";
import offerGamesSelectVue from "../../components/freeGames/offerGamesSelect.vue";
import CardHeading from "../../components/shared/CardHeading.vue";
import ConfirmDialog2 from "../../components/shared/ConfirmDialog2.vue";
import FilterSideBar from "../../components/shared/FilterSideBar.vue";
import { FEATURE_FLAGS } from "../../constants/constants";
import { isMobile } from "../../constants/helpers";
import localstorage from "../../mixins/localstorage";
import permissions from "../../mixins/permissions";
import table from "../../mixins/table";

export default {
	mixins: [permissions, table, localstorage],
	components: {
		// Table,
		offerDetailFormVue,
		CardHeading,
		ConfirmDialog2,
		FilterSideBar,
		FreeGamesForm,
	},
	data() {
		return {
			timeNow: Date.now(),
			currentStep: 0,
			steps: [
				{
					name: "Config",
					component: offerDetailFormVue,
					model: {},
				},
				{
					name: "Games",
					component: offerGamesSelectVue,
					model: [],
				},
			],

			emptyFilter: {
				created_from: undefined,
				created_to: undefined,
				valid_from: undefined,
				valid_to: undefined,
			},

			filter: undefined,

			createDialog: false,
			options: {},
			loading: false,
			total_records: 0,
			sectionColor: "primary",
			headers: [
				{ text: "Name", value: "name", align: "left" },
				{ text: "Code", value: "offer_code", align: "left" },
				{ text: "Spins", value: "spins", align: "right" },
				{ text: "From", value: "valid_from", align: "right" },
				{ text: "To", value: "valid_to", align: "right" },
				{
					text: "Gamble enabled",
					value: "gamble_enabled",
					align: "center",
					hide: () => !FEATURE_FLAGS.FREE_GAMES_GAMBLE_SUPPORT,
				},
				{
					text: "Creator",
					value: "internal",
					align: "center",
				},
				{
					text: "Rule",
					value: "offer_rules",
					align: "center",
					sortable: false,
				},
				{
					text: "Status",
					value: "status",
					align: "center",
					sortable: false,
					hide: () =>
						!this._usrFlagsSome({
							key: "free_games.list",
							val: this.permissions.DELETE,
						}),
				},
				{ text: "Actions", value: "actions", align: "center", sortable: false },
			],
		};
	},
	watch: {
		currentCasino() {
			if (this.options.page === 1) {
				this._loadTable();
			} else {
				this.options.page = 1;
			}
		},
	},
	computed: {
		isMobile,
		...mapGetters("freeGames", {
			list: "list",
		}),
		...mapGetters(["currentCasino"]),
		filterIsEmpty() {
			return _.isEqual(this.filter, this.emptyFilter);
		},
		rowIsClickable() {
			return this._usrFlagsSome({
				key: "free_games.detail",
				val: this.permissions.READ,
			});
		},
	},
	methods: {
		...mapActions("freeGames", {
			_loadList: "loadList",
			createOffer: "createOffer",
			updateOffer: "updateOffer",
		}),
		...mapMutations("freeGames", {
			setPagination: "pagination",
		}),
		...mapMutations("globalTicker", {
			sub: "subscribe",
			unsub: "unsubscribe",
		}),
		timeSetter() {
			this.timeNow = Date.now();
		},
		loadList() {
			let { valid_from, valid_to, created_from, created_to } = this.filter;

			const formatDate = (date) =>
				date ? `${date.substring(0, 19)}Z` : undefined;

			const payload = {
				validity: {
					start_date: formatDate(valid_from),
					end_date: formatDate(valid_to),
				},
				creation: {
					start_date: formatDate(created_from),
					end_date: formatDate(created_to),
				},
			};
			return this._loadList({ payload });
		},
		onFilter() {
			this._loadTable();
		},

		async onClear() {
			this.filter = this.emptyFilter;
			await this.$nextTick();
			this.onFilter();
		},

		async onRestoreItem(offer) {
			await this.updateOffer({
				offer_code: offer.offer_code,
				is_removed: false,
			});
			this._loadTable();
		},
		async onDeleteItem(offer) {
			await this.updateOffer({
				offer_code: offer.offer_code,
				is_removed: true,
			});
			this._loadTable();
		},
		handleClick(e) {
			if (!this.rowIsClickable) return;
			this.$router.push({
				name: "free-game-detail",
				params: {
					offer_id: e.offer_code,
					breadcrumbs: { title: `Offer ${e.offer_code}` },
				},
			});
		},
		openCreate() {
			this.createDialog = true;
		},
		nextStep() {
			this.currentStep++;
		},
		prevStep() {
			this.currentStep--;
		},
		async submit() {
			if ((await this.$refs.step0[0].submitHandler()) === false) {
				this.currentStep = 0;
				return;
			}
			const [config, games] = [this.steps[0].model, this.steps[1].model];
			const offer = { ...config, games, internal: true };
			this.createDialog = false;
			await this.createOffer(offer);
			this._loadTable();
		},
	},
	mounted() {
		this.sub(this.timeSetter);
	},
	beforeDestroy() {
		this.unsub(this.timeSetter);
	},
	created() {
		this.$syncAndAssign(this.$route.name, "filter");
		if (!this.filter || Object.keys(this.filter).length === 0) {
			this.filter = Object.assign({}, this.emptyFilter);
		}
	},
};
</script>
