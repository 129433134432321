<template>
	<v-card
		class="pa-0 ma-0"
		elevation="0">
		<v-row>
			<v-col
				cols="12"
				class="mt-3 mb-0 pb-0">
				<v-icon
					v-if="_created_from || _created_to"
					@click="clearCreated"
					class="primary--text">
					mdi-close
				</v-icon>
				Created
			</v-col>
			<v-col
				cols="12"
				lg="6"
				class="pb-0">
				<DatetimePicker
					:createdNow="false"
					:min="timeEpoch"
					title="Date From"
					ref="dateFrom"
					:key="_created_from"
					v-model="_created_from" />
			</v-col>
			<v-col
				cols="12"
				lg="6"
				class="pb-0">
				<DatetimePicker
					:createdNow="false"
					:min="timeEpoch"
					title="Date To"
					ref="dateTo"
					:key="_created_to"
					v-model="_created_to" />
			</v-col>
		</v-row>

		<v-row>
			<!-- TODO: Clear button -->
			<v-col
				cols="12"
				class="mt-3 mb-0 pb-0">
				<v-icon
					v-if="_valid_from || _valid_to"
					@click="clearCreated"
					class="primary--text">
					mdi-close
				</v-icon>
				Valid
			</v-col>
			<v-col
				cols="12"
				lg="6"
				class="pb-0">
				<DatetimePicker
					:createdNow="false"
					:min="timeEpoch"
					title="Date From"
					ref="dateFrom"
					:key="_valid_from"
					v-model="_valid_from" />
			</v-col>
			<v-col
				cols="12"
				lg="6"
				class="pb-0">
				<DatetimePicker
					:createdNow="false"
					:min="timeEpoch"
					title="Date To"
					ref="dateTo"
					:key="_valid_to"
					v-model="_valid_to" />
			</v-col>
		</v-row>

		<div class="d-flex justify-center mt-16">
			<v-btn
				:disabled="filterIsEmpty"
				@click="emitFilter"
				color="primary">
				Apply
			</v-btn>
		</div>
	</v-card>
</template>

<script>
import DatetimePicker from "../forms/DatetimePicker.vue";
// TODO : BE FILTER OBJECT
import EditComponent from "../jackpots/EditComponent.vue";
import { currencyCodes } from "../../constants/currencyCodes";

import { mapGetters, mapActions } from "vuex";

export default {
	model: {
		prop: "value",
		event: "change",
	},
	components: {
		DatetimePicker,
	},
	data() {
		return {
			currencyCodes,
			timeEpoch: new Date(0),
		};
	},
	props: {
		expanded: { type: Boolean, default: false },
		value: { type: Object, default: () => ({}) },
		autoFilter: {
			type: Boolean,
			default: false,
		},
		filterIsEmpty: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		_created_from: {
			get() {
				return this.value.created_from;
			},
			set(val) {
				this.$emit("change", {
					...this.value,
					created_from: val,
				});
			},
		},
		_created_to: {
			get() {
				return this.value.created_to;
			},
			set(val) {
				this.$emit("change", {
					...this.value,
					created_to: val,
				});
			},
		},
		_valid_from: {
			get() {
				return this.value.valid_from;
			},
			set(val) {
				this.$emit("change", {
					...this.value,
					valid_from: val,
				});
			},
		},
		_valid_to: {
			get() {
				return this.value.valid_to;
			},
			set(val) {
				this.$emit("change", {
					...this.value,
					valid_to: val,
				});
			},
		},
	},
	methods: {
		async emitFilter() {
			this.$emit("filter");
		},
		clearCreated() {
			this.$emit("change", {
				...this.value,
				created_from: undefined,
				created_to: undefined,
			});
		},
		clearValid() {
			this.$emit("change", {
				...this.value,
				valid_from: undefined,
				valid_to: undefined,
			});
		},
		onClear() {
			this.$emit("change", {
				created_from: undefined,
				created_to: undefined,
				valid_from: undefined,
				valid_to: undefined,
			});
		},
	},
};
</script>

<style>
.filterClear {
	position: fixed;
	bottom: 17vh;
	z-index: 2;
}
</style>
